import React from "react";


const Samples = () => {
    return (
        <div>
            <section className="Section4" id="samples">
                <div className="container-fluid ">
                    <div className="row">
                        <div className="col-12">
                            <h3 className="display-4 fw-bolder text-center mt-2 mb-3  text-white">Our Samples</h3>
                            <h1 className="display-6 text-center mb-4  text-white">The <b>Awesome </b>Collection for <b>You </b></h1>
                            <hr className="w-75 mx-auto" />
                        </div>
                    </div>
                    <div className="row mt-2">        
                        <div className="col-md-4">
                            <div  className="card p-3 sm-4 mx-auto my-auto">
                                <div className="card-body text-center">
                                        <i className="fa fa-star-half-o fa-4x mb-4 text-primary"></i>
                                    <h5 className="card-title mb-3 fs-1 fw-bold ">Sally</h5>
                                    <p className="card-text lead">
                                        A Punk-Styled party-hymn, just go for it! 
                                    </p>
                                    <iframe 
                                        title="Sally"
                                        src="https://open.spotify.com/embed/track/6SHb6B9JBR8iM2DrdRBVrN?utm_source=generator"
                                        width="100%"
                                        height="100%"
                                        frameBorder="0"
                                        allowFullScreen
                                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                                        style={{ borderRadius: '12px' }}
                                        loading="lazy"
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mt-2">
                            <div  className="card p-3 sm-4 mx-auto my-auto">
                                <div className="card-body text-center">
                                        <i className="fa fa-heartbeat fa-4x mb-4 text-primary"></i>
                                    <h5 className="card-title mb-3 fs-1 fw-bold">Feelings
                                     </h5>
                                    <p className="card-text lead">
                                        Thr softer side of our Rock!!! Do You like?
                                    </p>
                                    <iframe 
                                        title="Feelings"
                                        src="https://open.spotify.com/embed/track/7mEVqAIIEtWWHIvcAxnSaE?utm_source=generator"
                                        width="100%"
                                        height="100%"
                                        frameBorder="0"
                                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                                        allowFullScreen
                                         style={{ borderRadius: '12px' }}
                                        loading="lazy"
                                    ></iframe>
                                    {/*<i className="fa fa-play-circle-o fa-5x mb-4 text-primary"></i>*/}
                                </div>
                            </div>
                        </div>
                        <div  className="col-md-4 mt-2">
                            <div className="card p-3 sm-4 mx-auto my-auto">
                                <div className="card-body text-center">
                                        <i className="fa fa-users fa-4x mb-4 text-primary"></i>
                                    <h5 className="card-title mb-3 fs-1 fw-bold">Release
                                    </h5>
                                    <p className="card-text lead">
                                        Just "smash it" song, jump in and enjoy.
                                    </p>
                                    <iframe 
                                        id="Release"
                                        title="Spotify Track"
                                        src="https://open.spotify.com/embed/track/1LIlk5lm3g6EmSRGMP3n7f?utm_source=generator"
                                        width="100%"
                                        height="100%"
                                        frameBorder="0"
                                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                                        allowFullScreen
                                        style={{ borderRadius: '12px' }}
                                        loading="lazy"
                                    ></iframe>
                                    {/*<i className="fa fa-play-circle-o fa-5x mb-4 text-primary"></i>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-4 mt-2">
                            <div className="card p-3 sm-4 mx-auto my-auto">
                                <div className="card-body text-center">
                                        <i className="fa fa-comments fa-4x mb-4 text-primary"></i>
                                    <h5 className="card-title mb-3 fs-1 fw-bold">Words</h5>
                                    <p className="card-text lead">
                                        Just another Lovesong?
                                       <br></br> 
                                    </p>
                                    <iframe 
                                        title="Words"
                                        width="100%"
                                        height="100%"
                                        scrolling="no"
                                        frameBorder="no"
                                        allow="autoplay"
                                        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1449903838&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
                                    ></iframe>
                                    <div
                                        style={{
                                            fontSize: '10px',
                                            color: '#cccccc',
                                            lineHeight: 'normal',
                                            wordBreak: 'normal',
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            fontFamily: 'Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif',
                                            fontWeight: '100'
                                        }}
                                    >
                                        <a href="https://soundcloud.com/user-370801713" title="The Speech" target="_blank" rel="noreferrer" style={{ color: '#cccccc', textDecoration: 'none' }}>The Speech</a>
                                            {' · '}
                                        <a href="https://soundcloud.com/user-370801713/words" title="Words" target="_blank" rel="noreferrer" style={{ color: '#cccccc', textDecoration: 'none' }}>Words</a>
                                    </div>
                                </div>
                                {/*<i className="fa fa-play-circle-o fa-5x mb-4 text-primary"></i>*/}
                            </div>
                        </div>
                    
                        <div className="col-md-4 mt-2">
                            <div  className="card p-3 sm-4 mx-auto my-auto">
                                <div className="card-body text-center">
                                        <i className="fa fa-cogs fa-4x mb-4 text-primary"></i>
                                    <h5 className="card-title mb-3 fs-1 fw-bold">Promised Land
                                    </h5>
                                    <p className="card-text lead">
                                        Just enjoy 
                                        <br></br> 
                                    </p>
                                    <iframe 
                                        title="PromisedLAnd"                                        width="100%"
                                        height="100%"
                                        scrolling="no"
                                        frameBorder="no"
                                        allow="autoplay"
                                        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1449901474&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
                                    ></iframe>
                                    <div
                                        style={{                                            fontSize: '10px',
                                            color: '#cccccc',
                                            lineHeight: 'normal',
                                            wordBreak: 'normal',
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            fontFamily: 'Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif',
                                            fontWeight: '100'
                                        }}
                                    >
                                        <a href="https://soundcloud.com/user-370801713" title="The Speech" target="_blank" rel="noreferrer" style={{ color: '#cccccc', textDecoration: 'none' }}>The Speech</a>
                                            {' · '}
                                        <a href="https://soundcloud.com/user-370801713/promised-land" title="Promised Land" target="_blank" rel="noreferrer" style={{ color: '#cccccc', textDecoration: 'none' }}>Promised Land</a>
                                    </div>
                                </div>
                                {/*<i className="fa fa-play-circle-o fa-5x mb-4 text-primary"></i>*/}
                            </div>
                        </div>

                        <div className="col-md-4 mt-2">
                            <div  className="card p-3 sm-4 mx-auto my-auto">                                
                                <div className="card-body text-center">
                                        <i className="fa fa-music fa-4x mb-4 text-primary"></i>
                                    <h5 className="card-title mb-3 fs-1 fw-bold">Lost
                                    </h5>
                                    <p className="card-text lead">
                                        Something to think about
                                        <br></br> 
                                    </p>
                                    <iframe 
                                        title="Lost"
                                        width="100%"
                                        height="100%"
                                        scrolling="no"
                                        frameBorder="no"
                                        allow="autoplay"
                                        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1449902572&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
                                    ></iframe>
                                    <div
                                    style={{
                                        fontSize: '10px',
                                        color: '#cccccc',
                                        lineHeight: 'normal',
                                        wordBreak: 'normal',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        fontFamily: 'Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif',
                                        fontWeight: '100'
                                    }}
                                    >
                                        <a href="https://soundcloud.com/user-370801713" title="The Speech" target="_blank" rel="noreferrer" style={{ color: '#cccccc', textDecoration: 'none' }}>The Speech</a>
                                            {' · '}
                                        <a href="https://soundcloud.com/user-370801713/godzilla" title="Lost" target="_blank" rel="noreferrer" style={{ color: '#cccccc', textDecoration: 'none' }}>Lost</a>
                                    </div>
                                </div>
                                {/*<i className="fa fa-play-circle-o fa-5x mb-4 text-primary"></i>*/}
                            </div>
                        </div>
                    </div>
                </div>  
            </section>
        </div>
    )
}

export default Samples;