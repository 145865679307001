import React, { useState } from 'react';

function About(){
 
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
      // Erweitere den Abschnitt und lasse den Schalter verschwinden
      setExpanded(true);
    };

	return(
		<div>
            <section className="Section3" id="about">
            <div className="overflow-hidden">
                <div className="container-fluid my-p py-1 text-white mb-5">
                    <div className={`section-container ${expanded ? 'expanded' : ''}`}>

                            <div class="content">
                                <div className="row mx-auto my-auto">
                                <h1 className="display-4 fw-bolder text-center mt-2 mb-3">About Us</h1>
                                <hr className="w-75 mx-auto" />
                                    <div className="col-md-6 ">
                                        <img src="/assets/01.jpeg" alt="About"
                                        className="rounded mx-auto d-block w-100 mt-5 mb-5"/>    
                                    </div>
                                    <div className="col-md-6 mx-auto my-auto">
                                        <h1 className="display-5 fw-bolder text-center mt-5 mx-auto my-auto"><b>Who </b><b>We </b><b>Are</b></h1>
                                        <hr className="w-75 " />
                                        <p className="text-container lead fw-bolder mx-auto mt-5 mb-4">
                                            Four guys from Arnsberg meet shortly before the Corona Pandemic to start a new project. 
                                            Four guys two generations and different perspectives on life.  
                                            On one side the oldschool rockers Andreas Gelke (guitar) and Lars Günzler (drums).
                                            Shaped by 27 years of experience, their success with Ain't no use and the numerous gigs, 
                                            tours and recordings that come with it. They bring their experience and an experienced interplay into the 
                                            band.
                                        </p>
                                        <button className="btn btn-primary 
                                            rounded-pill px-4 py-2" style={{visibility:'hidden'}}>Get Started
                                        </button>
                                        <button className="btn btn-outline-primary 
                                            rounded-pill px-4 py-2 ms-2" style={{visibility:'hidden'}}>Contact us
                                        </button>
                                    </div>
                                    <p className="lead display-6 fw-bolder text-center mx-auto mt-5" >
                                        *                            
                                    </p> 
                                </div>    
                                <div className="row">
                                    <div className="col-md-6 d-flex align-items-center">
                                        <div clasname="mt-5">  
                                            <p className="text-container lead fw-bolder mx-auto mt-5 text-center" >
                                                Vier Jungs aus Arnsberg treffen sich kurz vor der Corona Pandemie, um ein neues Projekt zu starten. 
                                                Vier Jungs zwei Generationen und unterschiedliche Blickwinkel auf das Leben.  
                                                Auf der einen Seite die Oldschool Rocker Andreas Gelke (Gitarre) und Lars Günzler (Schlagzeug).
                                                Geprägt von 27 Jahren Erfahrung, ihrem Erfolg mit Ain’t no use und den damit verbundenen zahlreichen
                                                Auftritten, Touren und Aufnahmen. Sie bringen Ihre Erfahrung und ein routiniertes Zusammenspiel in die 
                                                Band ein.
                                            </p>
                                        </div>  
                                    </div>
                                    <div className="col-md-6">
                                    <img src="/assets/02.jpg" alt="About"
                                    className="rounded mx-auto d-block w-75  mt-5"/>    
                                </div>
                                </div>
                                <div className="row mx-auto my-auto">
                                        <div className="col-md-6  mt-5">
                                            <img src="/assets/03.jpg" alt="About"
                                            className="rounded mx-auto d-block w-75"/> 
                                        </div>    
                                        <div className="col-md-6 mx-auto my-auto"> 
                                            <p className="text-container lead fw-bolder mx-auto mt-5" >
                                                On the other side the rookies Patrick Stephan (bass) and Immanuel Möller (vocals). 
                                                They went to school together, but their first steps in life couldn't have been more different. 
                                                One as a successful DJ, manager and friend of electronic sounds. The other found only after an 
                                                excessive punk rock career, his way into a solid life.
                                            </p>
                                            <p className="lead display-6 fw-bolder text-center mx-auto mt-5" >
                                                *                            
                                            </p> 
                                            <p className="text-container lead fw-bolder mx-auto mt-5" >
                                                Auf der anderen Seite die Rookies Patrick Stephan (Bass) und Immanuel Möller (Gesang). 
                                                Zusammen zur Schule gegangen, jedoch könnten die ersten Schritte im Leben nicht unterschiedlicher gewesen sein. 
                                                Der eine als erfolgreicher DJ, Manager und Freund elektronsicher Klänge. Der andere fand erst nach einer 
                                                exzessiven Punk-Rock Karriere den Weg ins solide Leben.
                                            </p> 
                                        </div>
                                </div>
                                <div className="row mx-auto my-auto">
                                    <div className="col-md-6 mx-auto my-auto">
                                        <p className="text-container lead fw-bolder mx-auto mt-5 ">
                                            They contribute to the success of the band with their technical expertise and fresh thinking.  
                                            In the summer of 2022, the first 3 songs of your repertoire were recorded in the studio and released.  
                                            The radio premiere as well as the first live performance, followed suitably to the Open Arnsberg Festival. 
                                            It was clear to all, that makes you want more.
                                            </p>
                                        <p className="lead display-6 fw-bolder text-center mx-auto mt-5" >
                                            *                            
                                        </p> 
                                        <p className="text-container lead fw-bolder mx-auto mt-5" >
                                            Sie tragen mit ihrer technischen Expertise und dem frischen Denken zum Erfolg der Band bei.  
                                            Im Sommer 2022 wurden die ersten 3 Lieder Ihres Repertoires im Studio aufgenommen und veröffentlicht.  
                                            Die Radiopremiere so wie der erste Live Auftritt, folgte passend zum Open Arnsberg Festival. 
                                            Allen wurde klar, das macht Lust auf mehr.
                                        </p>
                                    </div>
                                    <div className="col-md-6">
                                        <img src="/assets/04.jpg" alt="About"
                                        className="rounded mx-auto d-block w-75  mt-5"/>    
                                    </div>
                                    <div className="col-md-6"></div> 
                                        <p className="text-container lead fw-bolder mx-auto mt-5" >
                                            All four are united by the mission to present their interpretation of rock music to the world. 
                                            They call it Modern Rock with influences from The Police, Led Zeppelin, Dire Straits to Foo Fighters 
                                            and many other bands of rock history. 
                                            But also influences from Rage Against the Machine, Pennywise Godsmack, Killing Joke and many more 
                                            younger legends are not missing.  Get ready for the modern rock of The Speech, versatile, 
                                            multifaceted and something for (almost) everyone. 
                                        </p>
                                    
                                </div>
                                <div className="row">   
                                        <img src="/assets/05.jpg" alt="About"
                                        className="rounded mx-auto d-block w-50  mt-2"/>  
                                </div>
                                <div className="row">   
                                        <p className="text-container lead fw-bolder mx-auto mt-5" >
                                            Alle vier verbindet die Mission, der Welt ihre Interpretation der Rockmusik zu präsentieren. 
                                            Sie nennen es Modern Rock mit Einflüssen von The Police, Led Zeppelin, Dire Straits bis Foo Fighters 
                                            und vielen anderen Bands der Rockgeschichte. 
                                            Aber auch Einflüsse von Rage Against the Machine, Pennywise Godsmack, Killing Joke und vielen weiteren 
                                            jüngeren Legenden fehlen nicht.  Macht Euch bereit für den Modern Rock von The Speech, vielseitig, 
                                            facettenreich und für (fast) jeden etwas dabei. 
                                        </p>
                                </div>
                                <div className="row">     
                                    <p className="lead display-6 fw-bolder text-center mx-auto mt-5" >
                                        ***
                                    </p>
                                </div>    
                                <div className="row">   
                                        <img src="/assets/06.jpg" alt="About"
                                        className="rounded mx-auto d-block w-50  mt-2"/>  
                                </div>
                                <div className="row">     
                                    <p className="lead display-6 fw-bolder text-center mx-auto mt-5" >
                                        ***
                                    </p>
                                </div>
                                <div className="row">     
                                    <h3 className="display-6 fw-bolder text-center fs-7 mx-auto mt-1 mb-5" >
                                        Together we live rock as its best
                                    </h3>
                                </div> 
                            </div>
                       
                    </div>
                    {!expanded && (
                    <div className="expand-switch" onClick={handleExpandClick}>
                        Klicke, um zu erweitern
                    </div>
                )}
                </div>
                
            </div>    
            </section>
		</div>
	)

}

export default About